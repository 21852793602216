import { FeatureFlagsInterface } from 'store/features/mainSliceTypes';
import { SettingsStateInterface } from 'store/features/settingsSlice';

import { DisplayConditions } from './MenuItems.types';

const isDevelopment = process.env.NODE_ENV === 'development';

const enableLogging = false; // Note: Use this to enable logging for development and display condition failures.

const logDisplayConditionFailure = (
  reason: string,
  url: string | undefined,
  details?: Record<string, unknown>,
) => {
  if (isDevelopment && enableLogging) {
    // eslint-disable-next-line no-console
    console.group(
      `%cMenu Item Display Condition Failed: ${url || 'No URL'}`,
      'color: orange; font-weight: bold;',
    );
    // eslint-disable-next-line no-console
    console.log(`Reason: ${reason}`);
    if (details) {
      // eslint-disable-next-line no-console
      console.log('Details:', details);
    }
    // eslint-disable-next-line no-console
    console.groupEnd();
  }
};

interface CheckDisplayConditionsProps {
  displayConditions?: DisplayConditions;
  featureFlags: FeatureFlagsInterface;
  cybsafeSettings: SettingsStateInterface;
  isDummyClient: boolean;
  userGroup?: number;
  url?: string;
}

export const checkDisplayConditions = ({
  displayConditions,
  featureFlags,
  cybsafeSettings,
  isDummyClient,
  userGroup,
  url,
}: CheckDisplayConditionsProps): boolean => {
  // If no display conditions, show the item
  if (!displayConditions) {
    return true;
  }

  // Check notVisibleWithFeatureFlags
  if (displayConditions.notVisibleWithFeatureFlags?.length) {
    const blockingFlags = displayConditions.notVisibleWithFeatureFlags.filter(
      (flag) => flag in featureFlags && featureFlags[flag]?.value,
    );
    if (blockingFlags.length > 0) {
      logDisplayConditionFailure('Blocked by feature flags', url, {
        blockingFlags,
      });
      return false;
    }
  }

  // Check notVisibleWithCybsafeSettings
  if (displayConditions.notVisibleWithCybsafeSettings?.length) {
    const blockingSettings = displayConditions.notVisibleWithCybsafeSettings.filter(
      (setting) => setting in cybsafeSettings && cybsafeSettings[setting],
    );
    if (blockingSettings.length > 0) {
      logDisplayConditionFailure('Blocked by Cybsafe settings', url, {
        blockingSettings,
      });
      return false;
    }
  }

  // Check requiredCybsafeSettings
  if (displayConditions.requiredCybsafeSettings?.length) {
    const missingSettings = displayConditions.requiredCybsafeSettings.filter(
      (setting) => !cybsafeSettings[setting],
    );
    if (missingSettings.length > 0) {
      logDisplayConditionFailure('Missing required Cybsafe settings', url, {
        missingSettings,
      });
      return false;
    }
  }

  // Check visibleToDemoClients
  if (
    displayConditions.visibleToDemoClients !== undefined &&
    isDummyClient &&
    !displayConditions.visibleToDemoClients
  ) {
    logDisplayConditionFailure('Not visible to demo clients', url, {
      isDummyClient,
    });
    return false;
  }

  // Check visibleToUserGroups
  if (
    displayConditions.visibleToUserGroups?.length &&
    userGroup &&
    !displayConditions.visibleToUserGroups.includes(userGroup)
  ) {
    logDisplayConditionFailure('User group not allowed', url, {
      userGroup,
      allowedGroups: displayConditions.visibleToUserGroups,
    });
    return false;
  }

  // Check visibleWithFeatureFlags
  if (displayConditions.visibleWithFeatureFlags?.length) {
    if (featureFlags) {
      const missingFlags = displayConditions.visibleWithFeatureFlags.filter(
        (flag) => !(flag in featureFlags) || !featureFlags[flag]?.value,
      );
      if (missingFlags.length > 0) {
        logDisplayConditionFailure('Missing required feature flags', url, {
          missingFlags,
        });
        return false;
      }
    }
  }

  return true;
};
