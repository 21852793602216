import { ClientInfo } from 'store/features/mainSliceTypes';

import { MenuItemType, menuItemSchema } from './MenuItems.types';

export const getSideBarMenuItems = (client: ClientInfo): MenuItemType[] => [
  {
    label: 'label_menuLandingPage',
    url: '/reports',
    icon: 'tachometer-alt',
    sub: [
      {
        label: 'label_menuLandingPage',
        url: '/reports',
        features: ['risk.report.basic'],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
        },
      },
      {
        label: 'label_menuHumanCyberHub',
        url: '/reports/admin-dashboard',
        features: ['risk.report.basic'],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
        },
      },
    ],
    displayConditions: {
      visibleToDemoClients: true,
      visibleToUserGroups: [1, 2, 3, 5, 7],
    },
  },
  {
    label: 'menu.reports',
    url: undefined,
    icon: 'chart-area',
    sub: [
      {
        label: 'label_report_library',
        url: '/reports/favourites',
        sideNavEntryPoint: true,
        sub: [
          {
            label: 'label_report_favourites',
            url: '/reports/favourites',
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
          {
            label: 'label_all_reports',
            url: '/reports/all-reports',
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
        ],
        tags: [{ name: 'Beta', color: 'cool-gray' }],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5, 7],
        },
      },
      {
        label: 'menu.behaviour.humanRiskManagement',
        url: '/reports/risks',
        features: ['risk.report.basic'],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5, 7],
        },
      },
      {
        label: 'menu.reports.behaviours',
        url: '/reports/behaviours',
        features: ['risk.report.basic'],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5, 7],
        },
      },
      {
        label: 'label_menuAdvice',
        url: '/reports/assist/card-views',
        features: [
          'on_demand_help',
          'on_demand_help.custom',
          'on_demand_help.report',
        ],
        sub: [
          {
            label: 'menu.reports.assistTopicComparison',
            url: '/reports/assist/card-views',
            features: ['on_demand_help.report'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
          {
            label: 'menu.reports.assist.people',
            url: '/reports/assist/users',
            features: ['on_demand_help.report'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
          {
            label: 'menu.reports.assist.activityLog',
            url: '/reports/assist/activity-logs',
            features: ['on_demand_help.report'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
        ],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5, 7],
        },
      },
      {
        label: 'label_menuCulture',
        url: '/reports/culture',
        features: ['culture.culture_survey', 'culture.report.security_culture'],
        sub: [
          {
            label: 'label_menuCultureOverview',
            url: '/reports/culture',
            features: ['culture.report.security_culture'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
          {
            label: 'menu.reports.culture.groupComparison',
            url: '/reports/culture/group-comparison',
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
          {
            label: 'label_menuSentiment',
            url: '/reports/sentiment',
            features: ['culture.report.sentiment'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
          {
            label: 'menu.reports.userCharacteristics',
            url: '/reports/user-information',
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
        ],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5, 7],
        },
      },
      {
        label: 'page.reports.learningActivity.section.title',
        url: '/reports/behaviour/learning-activity',
        features: ['engagement.report'],
        sub: [
          {
            label: 'page.reports.learningActivity.section.title',
            url: '/reports/behaviour/learning-activity',
            features: ['engagement.report'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
          {
            label: 'menu.engagementActivities',
            url: '/reports/engagement/activities',
            features: ['risk.report.basic'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
          {
            label: 'page.logs.engagementEmails.section.title',
            url: '/settings/email-activity',
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
          {
            label: 'label_menuProactivity',
            url: '/reports/behaviour/proactivity',
            features: ['proactivity.report'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
          {
            label: 'menu.securityHeroes',
            url: '/reports/security-heroes',
            features: ['security_heroes.report'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
        ],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
        },
      },
      {
        label: 'menu.reports.exposure',
        url: '/reports/behaviour/passphrase',
        features: ['saas_usage.report'],
        sub: [
          {
            label: 'label_menuPassphrase',
            url: '/reports/behaviour/passphrase',
            displayAsTab: true,
            features: ['passphrase.report'],
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
          {
            label: 'menu.passwordExposure',
            url: '/reports/password-exposure/overview',
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
          {
            label: 'page.reports.passwordExposure.compromisedUsers.title',
            url: '/reports/password-exposure/compromised-users',
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
          {
            label: 'pageTitle.saas',
            url: '/reports/saas-usage',
            features: ['saas_usage.report'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [2, 3, 4, 5, 7],
            },
          },
        ],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5, 7],
        },
      },
      {
        label: 'label_menuProtect',
        url: '/reports/protect/card-views',
        features: ['behaviour_goals.report', 'behaviour_goals'],
        sub: [
          {
            label: 'menu.reports.goalsComparison',
            url: '/reports/protect/card-views',
            features: ['behaviour_goals.report'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
          {
            label: 'menu.reports.goal.responses',
            url: '/reports/protect/goal-responses',
            tags: [{ name: 'Beta', color: 'cool-gray' }],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
          {
            label: 'menu.reports.protect.activityLog',
            url: '/reports/protect/activity-logs',
            features: ['behaviour_goals.report'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
        ],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5, 7],
        },
      },
      {
        label: 'menu.learning',
        url: '/reports/learning',
        features: [
          'learning.report.knowledge_and_compliance',
          'learning.role_specific_modules',
          'learning.story_style_modules',
          'learning.training_modules',
        ],
        sub: [
          {
            label: 'label_menuAwarenessOverview',
            url: '/reports/learning',
            features: ['learning.report.knowledge_and_compliance'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
          {
            label: 'menu.reports.learning.knowledge',
            url: '/reports/learning/knowledge',
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
          {
            label: 'pageTitle.learningCampaignReport',
            url: '/reports/learning/campaigns',
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
          {
            label: 'menu.reports.learning.moduleComparison',
            url: '/reports/learning/module-comparison',
            features: ['learning.report.knowledge_and_compliance'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
          {
            label: 'menu.reports.learning.groupComparison',
            url: '/reports/learning/group-comparison',
            features: ['learning.report.knowledge_and_compliance'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
          {
            label: 'menu.reports.learning.peopleComparison',
            url: '/reports/learning/people-comparison',
            features: ['learning.report.knowledge_and_compliance'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
        ],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5, 7],
          requiredCybsafeSettings: ['enableLearningReportsVersion1'],
        },
      },
      {
        label: 'menu.learning',
        url: '/admin/page/reports/learning',
        features: ['learning.report.knowledge_and_compliance'],
        tags: [{ name: 'New', color: 'teal' }],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5, 7],
          visibleWithFeatureFlags: ['use-new-learning-report'],
        },
      },
      {
        label: 'menu.phishing',
        url: '/reports/phishing',
        features: ['phishing.campaigns'],
        sub: [
          {
            label: 'menu.phishingReports.overview',
            url: '/reports/phishing',
            features: ['phishing.reports'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
          {
            label: 'menu.reports.phishing.campaignComp',
            url: '/reports/phishing/campaign-comparison',
            features: ['phishing.reports'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
          {
            label: 'menu.reports.phishing.groupComp',
            url: '/reports/phishing/group-comparison',
            features: ['phishing.reports'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
          {
            label: 'menu.reports.phishing.peopleComp',
            url: '/reports/phishing/people-comparison',
            features: ['phishing.reports'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
          {
            label: 'menu.reports.phishing.activityLog',
            url: '/reports/phishing/activity-log',
            features: ['phishing.campaigns'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
          {
            label: 'page.logs.phishingEmails.section.title',
            url: '/settings/phishing-activity',
            features: ['phishing.campaigns'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
        ],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5, 7],
        },
      },
      {
        label: 'menu.phishing',
        url: '/admin/page/reports/phishing',
        features: ['phishing.reports'],
        tags: [{ name: 'New', color: 'teal' }],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5, 7],
          visibleWithFeatureFlags: ['use-new-phishing-report'],
        },
      },
      {
        label: 'common.nudgesAlerts',
        url: '/admin/page/reports/notifications',
        features: ['nudges_alerts.core'],
        tags: [{ name: 'New', color: 'teal' }],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5, 7],
          visibleWithFeatureFlags: ['use-notifications-report'],
        },
      },
      {
        label: 'pageTitle.incidents',
        url: '/reports/incidents',
        tags: [{ name: 'Beta', color: 'cool-gray' }],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
        },
      },
      {
        label: 'page.reports.eventLog',
        url: '/reports/event-log',
        tags: [{ name: 'Beta', color: 'cool-gray' }],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
        },
      },
      {
        label: 'page.reports.csvReport.page.title',
        url: '/admin/csv-report',
        features: ['csv_download'],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5, 7],
        },
      },
    ],
    displayConditions: {
      visibleToDemoClients: true,
      visibleToUserGroups: [1, 2, 3, 5, 7],
    },
  },
  {
    label: 'menu.guide',
    url: undefined,
    icon: 'guide',
    sub: [
      {
        label: 'page.improveBehaviours.adviceSetup.section.title',
        url: '/admin/advice/topics',
        features: ['on_demand_help', 'on_demand_help.custom'],
        sub: [
          {
            label: 'label_assistCategories',
            url: '/admin/advice/topics',
            features: ['on_demand_help.custom', 'on_demand_help'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
          {
            label: 'label_assistQuestions',
            url: '/admin/advice/questions',
            features: ['on_demand_help.custom', 'on_demand_help'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
          {
            label: 'label_assistSettings',
            url: '/admin/advice/settings',
            features: ['on_demand_help.custom', 'on_demand_help'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
        ],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
        },
      },
      {
        label: 'page.alerts.breadcrumb',
        url: '/alerts',
        features: ['nudges_alerts.core'],
        sub: [
          {
            label: 'menu.alertCampaigns',
            url: '/alerts',
            features: ['nudges_alerts.core'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
          {
            label: 'page.alerts.templates.library.breadcrumb',
            url: '/alerts/library',
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
        ],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: undefined,
        },
      },
      {
        label: 'label_menuBehaviourNudges',
        url: '/nudges',
        features: ['nudges_alerts.core', 'learning.alerts'],
        sub: [
          {
            label: 'menu.nudgeCampaigns',
            url: '/nudges',
            features: ['nudges_alerts.core'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
          {
            label: 'menu.nudgeLibrary',
            url: '/nudges/library',
            features: ['nudges_alerts.core', 'learning.alerts'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
        ],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5, 7],
        },
      },
      {
        label: 'label_menuCulture',
        url: '/settings/culture',
        features: ['culture.culture_survey'],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
        },
      },
      {
        label: 'page.improveBehaviours.goals.section.title',
        url: '/admin/goals',
        features: [
          'behaviour_goals',
          'behaviour_goals.delivery.slack',
          'behaviour_goals.delivery.teams',
        ],
        sub: [
          {
            label: 'page.improveBehaviours.goals.section.title',
            url: '/admin/goals',
            features: [
              'behaviour_goals',
              'behaviour_goals.delivery.slack',
              'behaviour_goals.delivery.teams',
            ],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
          {
            label: 'common.settings',
            url: '/admin/goals/settings',
            displayAsTab: true,
            features: [
              'behaviour_goals',
              'behaviour_goals.delivery.slack',
              'behaviour_goals.delivery.teams',
            ],
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
        ],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
        },
      },
      {
        label: 'menu.learning',
        url: '/admin/learning-campaigns',
        sub: [
          {
            label:
              'page.admin.createLearningCampaign.crealearningCampaignsOverview',
            url: '/admin/learning-campaigns',
            features: [
              'learning.role_specific_modules',
              'learning.story_style_modules',
              'learning.training_modules',
            ],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
          {
            label: 'menu.moduleLibrary',
            url: '/admin/module-library',
            tags: [{ name: 'New', color: 'teal' }],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
          {
            label: 'page.improveBehaviours.refresherTests.section.title',
            url: '/settings/refresher-tests',
            features: [
              'learning.role_specific_modules',
              'learning.story_style_modules',
              'learning.training_modules',
            ],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
          {
            label: 'page.improveBehaviours.learningSettings.section.title',
            url: '/settings/learning',
            features: [
              'learning.role_specific_modules',
              'learning.story_style_modules',
              'learning.training_modules',
            ],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
        ],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
        },
      },
      {
        label: 'menu.label_userMessages',
        url: '/admin/user-insights',
        features: ['user_messages'],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
        },
      },
    ],
    displayConditions: {
      visibleToDemoClients: true,
      visibleToUserGroups: [1, 2, 3, 5],
    },
  },
  {
    label: 'menu.phish',
    url: '/settings/phishing',
    icon: 'phish',
    sub: [
      {
        label: 'page.improveBehaviours.phishingCampaigns.section.title',
        url: '/settings/phishing',
        features: ['phishing.campaigns'],
        displayAsTab: true,
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5, 7],
        },
      },
      {
        label: 'menu.improveBehaviours.phishingEmailLibrary',
        url: '/settings/phishing-emails',
        features: ['phishing.templates'],
        displayAsTab: true,
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
        },
      },
      {
        label: 'menu.improveBehaviours.landingPagesLibrary',
        url: '/settings/phishing-landing-pages',
        features: ['phishing.templates'],
        displayAsTab: true,
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
        },
      },
      {
        label: 'menu.improveBehaviours.interventionPagesLibrary',
        url: '/settings/phishing-intervention-pages',
        features: ['phishing.templates'],
        displayAsTab: true,
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
        },
      },
    ],
    displayConditions: {
      visibleToDemoClients: true,
      visibleToUserGroups: [1, 2, 3, 5],
    },
  },
  {
    label: 'menu.respond',
    url: undefined,
    icon: 'respond',
    sub: [
      {
        label: 'page.alerts.breadcrumb',
        url: '/alerts',
        features: ['nudges_alerts.core'],
        sub: [
          {
            label: 'menu.alertCampaigns',
            url: '/alerts',
            features: ['nudges_alerts.core'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
          {
            label: 'pageTitle.alertLibrary',
            url: '/alerts/library',
            features: ['nudges_alerts.core'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
        ],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
        },
      },
      {
        label: 'pageTitle.behaviourInterventions',
        url: '/settings/behaviour-interventions',
        features: ['nudges_alerts.triggers'],
        tags: [{ name: 'Beta', color: 'cool-gray' }],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
        },
      },
      {
        label: 'label_menuBehaviourNudges',
        url: '/nudges',
        features: ['nudges_alerts.core', 'learning.alerts'],
        sub: [
          {
            label: 'menu.nudgeCampaigns',
            url: '/nudges',
            features: ['nudges_alerts.core'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5, 7],
            },
          },
          {
            label: 'menu.nudgeLibrary',
            url: '/nudges/library',
            features: ['nudges_alerts.core', 'learning.alerts'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
        ],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5, 7],
        },
      },
      {
        label: 'page.workflowReport.title',
        url: '/workflows',
        features: ['workflow.core'],
        sub: [
          {
            label: 'page.workflowReport.title',
            url: '/workflows',
            features: ['workflow.core'],
            tags: [{ name: 'Beta', color: 'cool-gray' }],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
          {
            label: 'pageTitle.workflowLibrary',
            url: '/workflows/library',
            features: ['workflow.core'],
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
        ],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
        },
      },
    ],
    displayConditions: {
      visibleToDemoClients: true,
      visibleToUserGroups: [1, 2, 3, 5],
    },
  },
  {
    label: 'Divider',
    url: undefined,
    divider: true,
    displayConditions: {
      visibleToDemoClients: true,
      visibleToUserGroups: [1, 2, 3, 5, 7],
    },
  },
  {
    label: 'menu.people',
    url: '/admin/people',
    icon: 'users',
    sub: [
      {
        label: 'label_menuPeopleOverview',
        url: '/admin/people',
        displayAsTab: true,
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5, 7],
        },
      },
      {
        label: 'page.people.groupManagement.section.title',
        url: '/settings/group-management',
        displayAsTab: true,
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
        },
      },
    ],
    displayConditions: {
      visibleToDemoClients: true,
      visibleToUserGroups: [1, 2, 3, 5, 7],
    },
  },
  {
    label: 'pageTitle.integrations',
    url: '/settings/integrations',
    icon: 'integrations',
    displayConditions: {
      visibleToDemoClients: true,
      visibleToUserGroups: [1, 2, 3, 5],
    },
    sub: [
      {
        label: 'pageTitle.integrations',
        url: '/settings/integrations',
        features: ['integrations.core'],
        displayAsTab: true,
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
        },
      },
      {
        label: 'page.settings.apiToken.title',
        url: '/settings/integrations/api-token',
        features: ['integrations.core', 'public_api'],
        displayAsTab: true,
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
          visibleWithFeatureFlags: ['use-public-api'],
        },
      },
      {
        label: 'page.reports.behaviour.scoreOverTime.behaviour',
        url: '/settings/integrations/security-behaviours-summary',
        features: ['integrations.core'],
        displayAsTab: true,
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
          visibleWithFeatureFlags: ['use-security-behaviour-summary'],
        },
      },
    ],
  },
  {
    label: 'label_menuSettings',
    url: undefined,
    icon: 'cog',
    sub: [
      {
        label: 'page.settings.accessManagement.section.title',
        url: '/settings/identity-management/login',
        sideNavEntryPoint: true,
        sub: [
          {
            label: 'page.settings.identityManagement.tab.login',
            url: '/settings/identity-management/login',
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
          {
            label: 'page.settings.identityManagement.tab.userProvisioning',
            url: '/settings/identity-management/user-provisioning',
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
        ],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
        },
      },
      {
        label: 'pageTitle.platformSettings',
        url: '/settings/platform',
        sub: [
          {
            label: 'pageTitle.platformSettings',
            url: '/settings/platform',
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
          {
            label: 'page.admin.organisation.settings.branding.title',
            url: '/settings/organisation/customize-branding',
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
            },
          },
          {
            label: 'pageTitle.emailBranding',
            url: '/settings/email-branding',
            displayAsTab: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [1, 2, 3, 5],
              visibleWithFeatureFlags: ['use-email-branding'],
            },
          },
        ],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
        },
      },
      {
        label: 'page.settings.organisationDetails.section.title',
        url: '/settings/organisation',
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5],
        },
      },
      {
        label: 'label_menuOrganisationManagement',
        url: '/settings/organisation-management',
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 5],
        },
      },
    ],
    displayConditions: {
      visibleToDemoClients: true,
      visibleToUserGroups: [1, 2, 3, 5],
    },
  },
  {
    label: 'menu.resources',
    url: undefined,
    icon: 'book-reader',
    sub: [
      {
        label: 'page.resources.awarenessToolkit.section.title',
        url: '/awareness/toolkit',
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5, 7],
        },
      },
      {
        label: 'page.resources.securityBehavioursDatabase.section.title',
        url: 'https://www.cybsafe.com/research/security-behaviour-database/',
        isExternalLink: true,
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5, 7],
        },
      },
      {
        label: 'page.support.helpcentre.section.title',
        url: 'https://help.cybsafe.com/en/',
        isExternalLink: true,
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5, 7],
        },
      },
      {
        label: 'common.status',
        url: 'https://status.cybsafe.com/',
        isExternalLink: true,
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [1, 2, 3, 5, 7],
        },
      },
      {
        label: 'HubSpot',
        url: `https://app.hubspot.com/contacts/4820846/company/${client.hubspotId}`,
        isExternalLink: true,
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [2],
        },
      },
      {
        label: 'Intercom',
        url: `https://app.intercom.com/a/apps/ya7brvp1/companies/${client.intercomId}/users`,
        isExternalLink: true,
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [2],
        },
      },
      {
        label: 'Metabase',
        url: undefined,
        sub: [
          {
            label: 'Client summary dashboard',
            url: `https://metabase.cybsafedev.com/dashboard/21-client-summary-dashboard?client_id=${client.id}`,
            isExternalLink: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [2],
            },
          },
          {
            label: 'Basic adoption',
            url: `https://metabase.cybsafedev.com/dashboard/312-feature-set-up-by-customer?customer_id=${client.id}`,
            isExternalLink: true,
            displayConditions: {
              visibleToDemoClients: false,
              visibleToUserGroups: [2],
            },
          },
          {
            label: 'User provisioning',
            url: `https://metabase.cybsafedev.com/dashboard/49-cybsafe-scim-stats-201118-jw?client_id=${client.id}`,
            isExternalLink: true,
            displayConditions: {
              visibleToDemoClients: true,
              visibleToUserGroups: [2],
            },
          },
        ],
        displayConditions: {
          visibleToDemoClients: true,
          visibleToUserGroups: [2],
        },
      },
    ],
    displayConditions: {
      visibleToDemoClients: true,
      visibleToUserGroups: [1, 2, 3, 5, 7],
    },
  },
];

export const getSideBarItems = (client: ClientInfo): MenuItemType[] =>
  menuItemSchema.array().parse(getSideBarMenuItems(client));
