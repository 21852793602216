import { z } from 'zod';

const tagColorNames = [
  'red',
  'magenta',
  'purple',
  'blue',
  'cyan',
  'teal',
  'green',
  'gray',
  'cool-gray',
  'warm-gray',
  'high-contrast',
  'outline',
] as const;
const tagSchema = z.object({
  name: z.string(),
  color: z.enum(tagColorNames),
});

// Define a regex pattern for camelCase
const camelCasePattern = /^[a-z]+([A-Z][a-z\d]*)*$/;

// Create a custom validation for camelCase
const camelCaseString = z.string().refine((val) => camelCasePattern.test(val), {
  message: 'String must be in camelCase',
});

const displayConditionsSchema = z.object({
  notVisibleWithFeatureFlags: z.array(z.string()).optional(),
  // validate cybsafe settings do not have spaces or underscores
  requiredCybsafeSettings: z.array(camelCaseString).optional(),
  notVisibleWithCybsafeSettings: z.array(camelCaseString).optional(),
  visibleToDemoClients: z.boolean().optional(),
  visibleToUserGroups: z.array(z.number()).optional(),
  visibleWithFeatureFlags: z.array(z.string()).optional(),
});
export type DisplayConditions = z.infer<typeof displayConditionsSchema>;

export const menuItemSchema: z.ZodType<{
  label: string;
  url?: string;
  icon?: string;
  sub?: any[]; // Recursive structure
  displayConditions?: DisplayConditions;
  tags?: any[];
  features?: string[];
  sideNavEntryPoint?: boolean;
  displayAsTab?: boolean;
  active?: boolean;
  divider?: boolean;
  isExternalLink?: boolean;
  expanded?: boolean;
}> = z.lazy(() =>
  z.object({
    label: z.string(),
    url: z.string().optional(),
    icon: z.string().optional(),
    sub: z.array(menuItemSchema).optional(), // Recursive definition
    displayConditions: displayConditionsSchema.optional(),
    tags: z.array(tagSchema).optional(),
    features: z.array(z.string()).optional(),
    sideNavEntryPoint: z.boolean().optional(),
    displayAsTab: z.boolean().optional(),
    active: z.boolean().optional(),
    divider: z.boolean().optional(),
    isExternalLink: z.boolean().optional(),
    expanded: z.boolean().optional(),
  }),
);

export type MenuItemType = z.infer<typeof menuItemSchema>;

export type MenuData = {
  topBarMenuItems: MenuItemType[];
  sideBarMenuItems: MenuItemType[];
};
