import { motion } from 'framer-motion';
import styled from 'styled-components';

import { unit } from 'theme';

export const FILTERS_WIDTH = 340;

export const FilterContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.componentColors.modalContentBg};
  border-left: 1px solid
    ${({ theme }) => theme.componentColors.card.borderColor};
`;

export const FiltersPanel = styled.div<{ $direction: DirectionType }>`
  position: absolute;
  top: auto;
  height: 100vh;
  z-index: 5999; // less than our Modal component which is set to 9000 + less than our UserMenu which is set to 6000.
  ${({ $direction }) => ($direction === 'rtl' ? 'left: 0;' : 'right: 0;')}
`;

export const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${unit(2)} ${unit(2)} ${unit(1)};

  button.cds--btn--ghost:not([disabled]) svg {
    fill: var(--cds-icon-primary) !important;
  }
`;

export const FilterContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${unit(2)};
  overflow: auto;
  padding: ${unit(1)} ${unit(2)} ${unit(2)};
`;

export const FilterFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${unit(3)};
`;

export const DateRangeDropdownComboContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${unit(1)};
  > .cds--dropdown__wrapper {
    width: 100%;
  }
`;

export const DeprecatedFiltersSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${unit(1)};
  width: 100%;
  > button {
    margin-top: ${unit(3)};
  }
`;

export const DeprecatedFiltersFields = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: ${unit(1)};
  align-items: baseline;
  flex: 1;
  max-width: 100%;
  /* Below styling is for the date range component to be full width for the deprecated filters */
  .cds--date-picker--range .cds--date-picker-container,
  .cds--date-picker-input__wrapper > span,
  .cds--date-picker--range .cds--date-picker__input {
    inline-size: 100%;
  }
  .cds--date-picker.cds--date-picker--range {
    width: 100%;
  }
`;

export const ActiveFiltersTag = styled.div`
  display: flex;
  justify-content: center;
  background-color: var(--cds-background-inverse);
  color: var(--cds-text-inverse);
  min-inline-size: 1.5rem;
  border-radius: 1rem;
`;
