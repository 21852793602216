import { UserInfo } from 'store/features/mainSliceTypes';

import { MenuItemType, menuItemSchema } from './MenuItems.types';

const getTopMenuItems = (
  id: UserInfo['id'],
  publicId: UserInfo['publicId'],
) => [
  {
    label: 'label_home',
    url: '/',
    displayConditions: {
      visibleToDemoClients: true,
    },
  },
  {
    label: 'menu.yourLearningModules',
    url: '/learning',
    features: [
      'learning.delivery.friends_family',
      'learning.role_specific_modules',
      'learning.story_style_modules',
      'learning.training_modules',
    ],
    displayConditions: {
      requiredCybsafeSettings: ['enableUserProgress'],
      visibleToDemoClients: true,
      notVisibleWithCybsafeSettings: ['lmsModeEnabled'],
    },
  },
  {
    label: 'label_menuAdvice',
    url: '/learning/advice',
    // helpArticleUrl: 'https://help.cybsafe.com/en/articles/4153237-advice',
    displayConditions: {
      requiredCybsafeSettings: ['enableAssist'],
      visibleToDemoClients: true,
    },
  },
  {
    label: 'label_menuProtect',
    url: '/goals',
    // helpArticleUrl: 'https://help.cybsafe.com/en/articles/5991400-goals',
    displayConditions: {
      requiredCybsafeSettings: ['enableProtect'],
      visibleToDemoClients: true,
    },
  },
  {
    label: 'label_menuNewsFeed',
    url: '/news',
    displayConditions: {
      requiredCybsafeSettings: ['enableNewsfeed'],
      visibleToDemoClients: true,
    },
  },
  {
    label: 'label_menuProgress',
    url: `/reports/user/${publicId}/${id}`,
    displayConditions: {
      requiredCybsafeSettings: ['enableUserProgress'],
      visibleToDemoClients: true,
    },
  },
  {
    label: 'Feedback',
    url: '/feedback-questions',
    displayConditions: {
      visibleToDemoClients: false,
      visibleToUserGroups: [2, 3, 4, 5, 7],
      visibleWithFeatureFlags: ['barclays-digital-wings'],
    },
  },
  {
    label: 'Back to Digital Wings',
    url: 'https://digital.wings.uk.barclays/',
    isExternalLink: true,
    displayConditions: {
      visibleToDemoClients: false,
      visibleToUserGroups: [1, 2, 3, 4, 5, 6, 7],
      visibleWithFeatureFlags: ['barclays-digital-wings'],
    },
  },
  {
    label: 'Report Fraud & Scams',
    url: 'https://www.barclays.co.uk/fraud-and-scams/reporting-scams-and-reporting-fraud/',
    isExternalLink: true,
    displayConditions: {
      visibleToDemoClients: false,
      visibleToUserGroups: [1, 2, 3, 4, 5, 6, 7],
      visibleWithFeatureFlags: ['barclays-digital-wings'],
    },
  },
];

export const getTopBarItems = (
  id: UserInfo['id'],
  publicId: UserInfo['publicId'],
): MenuItemType[] =>
  menuItemSchema.array().parse(getTopMenuItems(id, publicId));
