import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../createStore';

export interface SettingsStateInterface {
  clientChosenLanguages: string[];
  cultureAssessmentStandalone: boolean | null;
  cultureSurveyVersion2Enabled: boolean | null;
  departmentSeparator: boolean | null;
  editableLabelsEnabled: boolean | null;
  enableNotifications: boolean | null;
  menuVersion: number;
  phishingMassManagement: boolean | null;
  userUiTheme: 'light' | 'dark';
  enableSecurityHeroesWidget: boolean | null;
  userCharacteristicsSurvey: boolean | null;
  setEulaButtonCopyToOk: boolean | null;
  ssoBackstop: boolean | null;
  enablePassphraseLogin: boolean | null;
  visualEditorDisabled: boolean | null;
  eulaSetButtonCopyToOk: boolean | null;
  lmsModeEnabled: boolean | null;
}

const initialState: SettingsStateInterface = {
  clientChosenLanguages: ['en'],
  cultureAssessmentStandalone: null,
  cultureSurveyVersion2Enabled: null,
  departmentSeparator: null,
  editableLabelsEnabled: null,
  enableNotifications: null,
  enableSecurityHeroesWidget: null,
  menuVersion: 2,
  phishingMassManagement: null,
  userUiTheme: 'light',
  userCharacteristicsSurvey: null,
  setEulaButtonCopyToOk: null,
  ssoBackstop: null,
  enablePassphraseLogin: null,
  visualEditorDisabled: null,
  eulaSetButtonCopyToOk: null,
  lmsModeEnabled: null,
};

export const settingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    setSettings: (state, action) => {
      /* If client hasn't selected their languages, default to "en" */
      const clientChosenLanguages =
        action.payload.clientChosenLanguages &&
        action.payload.clientChosenLanguages.length > 0
          ? action.payload.clientChosenLanguages
          : ['en'];
      return { ...action.payload, clientChosenLanguages };
    },
    setClientChosenLanguages: (state, action) => {
      state.clientChosenLanguages = action.payload;
    },
  },
});

/* Actions */
export const { setSettings, setClientChosenLanguages } = settingsSlice.actions;

/* Selectors */
export const selectSettings = (state: RootState) => state.userSettings;

export default settingsSlice.reducer;
